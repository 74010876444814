import React from 'react';
import Header from '../Header/Header';
const LayoutComp = (props) => {
  return (
    <>
      {props.header && <Header />}
       <div style={{height:'90dvh', overflow:'hidden'}}>
       {props.children}
       </div>
    </>
  );
};

export default LayoutComp;
