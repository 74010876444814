import React from 'react';
const ThemeContext = React.createContext();
const ThemeUpdateContext = React.createContext();

export function useTheme() {
  return React.useContext(ThemeContext);
}
export function useThemeUpdate() {
  return React.useContext(ThemeUpdateContext);
}

export function ThemeProvider({ children }) {
  const [dark, setDark] = React.useState(true);
  const toggleTheme = () => {
    setDark((prev) => !prev);
  };

  return (
    <ThemeContext.Provider value={dark}>
      <ThemeUpdateContext.Provider value={toggleTheme}>
        {children}
      </ThemeUpdateContext.Provider>
    </ThemeContext.Provider>
  );
}
