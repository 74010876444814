import React, { useEffect, useState } from 'react';
import './json.scss'
import FileCopyIcon from '@mui/icons-material/FileCopy';
import Button from '@mui/material/Button';
import copy from 'clipboard-copy';
// import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import downloadIcon from './Group(1).svg'

const pageData = {
  name: 'Json validator',
}
function Jsonlint() {
  const [input, setInput] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [c, setC] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  // const [Show, setShow] = useState(false);


  const [errorLine, setErrorLine] = useState(null);

  const handleInputChange = (event) => {
    setC(false)
    const inputValue = event.target.value;
    setInput(inputValue);
  };
  function downloadJSON(data) {

    // Create Blob object from JSON string
    const currentDate = new Date().toISOString().slice(0, 10); // Format: "yyyy-mm-dd"
    const postfix = `_${currentDate}`;

    // Append postfix to the filename
    const filenameWithPostfix = `${'jsonDownload'}${postfix}.json`;
    const blob = new Blob([data], { type: 'application/json' });
    // Create URL for Blob object
    const url = URL.createObjectURL(blob);

    // Create anchor element
    const a = document.createElement('a');
    a.href = url;
    a.download = filenameWithPostfix;

    // Simulate click on anchor element to trigger download
    a.click();

    // Cleanup: Revoke the URL
    URL.revokeObjectURL(url);
  }

  function check() {
    setC(true)
    try {
      JSON.parse(input);
      setIsValid(true);
      setErrorLine(null); // Reset error line if parsing succeeds
    } catch (error) {
      setIsValid(false);
      const errorMessage = error.message;
      console.log(errorMessage)
      const lineNumberMatch = errorMessage.match(/line (\d+)/);
      if (lineNumberMatch && lineNumberMatch.length > 1) {
        const lineNumber = parseInt(lineNumberMatch[1]);
        setErrorLine(lineNumber);
      }
    }
  }
  const handleCopyClick = () => {
    copy(input ? input : '')
      .then(() => {
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 2000); // Reset "isCopied" after 2 seconds
      })
      .catch((err) => {
        console.error('Failed to copy text: ', err);
      });
  };


  React.useEffect(() => {
    setIsValid(false);
    setC(false)
  }, [input])

  const DisplayTextWithLineNumbers = () => {
    return (
      <div>
        <div className='resultButtonSection'>
          <Button
            sx={{ marginBottom: '35px', color: 'darkblue' }}
            startIcon={<FileCopyIcon />}
            disabled={input.length === 0 || !isValid}
            onClick={handleCopyClick}
          ></Button>
          <Button
            sx={{ marginBottom: '35px', color: 'darkblue' }}
            disabled={input.length === 0 || !isValid}
            onClick={() => { downloadJSON(input) }}
          >
            <img className='download-btn' src={downloadIcon} alt='imgNotfound' />
          </Button>
        </div>
          {
            input && <div className='formatContainer' >
              {input && c && <div className="line-numbers" style={{ backgroundColor: isValid ? '#dff7e2' : '#f7dfe4' }}>
                {c && input.split('\n').map((line, index) => (
                  <div key={index}>
                    {index + 1}
                  </div>
                ))}
              </div>}
              <div className="content" >
                {c && input.split('\n').map((line, index) => (
                  <div key={index} style={{ color: index + 1 === errorLine ? 'red' : 'inherit', textAlign: 'left' }}>
                    {line}
                  </div>
                ))}
              </div>
            </div>
          }
      </div>
    );
  };

const buttonStyle = {
    marginTop:'50px',
    width: '90%',
    padding: '10px',
    height:'75%',
    overflow: 'scroll'
    , borderRadius: '15px',
    backgroundColor: 'white',
    '&:focus': {
      boxShadow: '',
      backgroundColor: 'darkblue',
    },
    '&:active': {
      backgroundColor: 'darkblue',
    },
  };



  return (
    <div className='masterContainer'>
      <div>
        <h2 className='title'>{pageData.name}</h2>
      </div>


      <div style={{ height: '40px' }}>
        {(input && c && errorLine) && (
          <div className='errorSection'>
            Error in line {errorLine}
          </div>
        )}
        {input && isValid && (
          <div className='validSection'>
            {input.length > 0 && <span>{'Valid JSON  '}</span>}
          </div>
        )}
      </div>


      <div className='masterFormatContainer'>
        <div className='inputBox11'>
          <textarea
            sx={{
              borderColor: 'darkblue',
              '&:focus': {
                borderColor: 'darkblue',
                boxShadow: '0 0 0 2px rgba(0, 0, 255, 0.3)',
              },
            }}
            // ref={prevInputTextRef}
            value={input}
            minRows={12}
            onChange={handleInputChange}
            placeholder="Type something..."
            style={buttonStyle}
            spellCheck={false}
          />

          <div className='buttonSection'>
            <button disabled={!input} style={{ width: '190px' }} onClick={check} className='gen-btn'>Check </button>
            <button style={{ width: '100px' }} onClick={() => { setInput('') }} className='clear-btn'>Clear </button>
          </div>

        </div>
        <div className='resultScreen' >
          <DisplayTextWithLineNumbers />
        </div>
      </div>
    </div>
  );
}

export default Jsonlint;
