import PassGen from './Component/pass/PassGen';
import Secret from './Component/secretView/Secret';
import Jsonlint from './Component/JsonLint/Jsonlint';
import Home from './Component/Home/Home';
import DiffChecker from './Component/Diff/DiffChecker';
export const routeConfig = [
  {
    path: '/',
    name: 'Password-gen',
    header: true,
    component: Home,
  },
  {
    path: '/secret',
    name: 'Password-gen',
    header: true,
    component: PassGen,
  },
  {
    path: '/pass-gen/:id',
    header: true,
    name: 'secret-view',
    component: Secret,
  },
  {
    path: '/jsonLint',
    header: true,
    name: 'json-lint',
    component: Jsonlint,
  },
  {
    path: '/diffChecker',
    header: true,
    name: 'Diff  checker',
    component: DiffChecker,
  },
];
