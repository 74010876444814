import './App.css';
import { ThemeProvider } from './context/Context';
import { Routes, Route } from 'react-router-dom';
import { routeConfig } from './routeConfig';
import LayoutComp from './Component/Layout/Layout';

function App() {
  const getRoutes = (route, key) => {
    let Component = route?.component;
    return (
      <Route
        key={key}
        path={route.path}
        element={
          <LayoutComp header={route.header}>
            {' '}
            <Component />
          </LayoutComp>
        }
      ></Route>
    );
  };
  return (
    <ThemeProvider>
      <div className="App">
        <Routes>
          {routeConfig.map((el, key) => {
            return getRoutes(el, key);
          })}
        </Routes>
      </div>
    </ThemeProvider>
  );
}

export default App;
