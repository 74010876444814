import React from 'react'
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import './secret.scss'
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Tooltip } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import sv from './secretView.jpg'
import CryptoJS from 'crypto-js';
import axiosConfig from '../../axios/axios';
const Secret = () => {
  const { id } = useParams();
  const [secret, setSecret] = React.useState('')
  const [error, setError] = React.useState()
  const [view, setView] = React.useState(false)
  const [imageSrc, setImageSrc] = React.useState(null);
  const secretKey =process.env.REACT_APP_SECRET;
  const handleDecrypt =  (text) => {
    const decrypted = CryptoJS.AES.decrypt(text, secretKey).toString(CryptoJS.enc.Utf8);
    setSecret(decrypted);
  };
  React.useEffect(() => {
    axiosConfig.get(`pass-gen/${id},`).then(async (res) => {
      // eslint-disable-next-line eqeqeq
      if (res.status == 200)
      // eslint-disable-next-line no-lone-blocks
      {
        // eslint-disable-next-line eqeqeq
        if (res.data.status == 200) {
          res.data.pwd&& await handleDecrypt(res.data.pwd)
        }
        else {
          setError(res.data.message)
        }
      }
    }).catch((e) => { 
   
        e.message? setError(e.message):setError('An error occurred while fetching data.');
       })

    import('./tu.jpg')
      .then((module) => {
        setImageSrc(module.default);
      })
      .catch((error) => {
        console.error('Error loading image:', error);
      });

      return ()=>{
      }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function Empty() {
    return (
      <Grid container sx={{ justifyContent: 'center' }}>
        <Grid item md={6}>
          <div className='center'>
            {error?
            <>
          <div>
            <Box component="span" sx={{  borderRadius: '10px', margin: '10px', border: '1px dashed darkblue', paddingY:'20px', paddingX:'40px' }}>
              {JSON.stringify(error)}
            </Box>
          </div>
          </>:<Skeleton  variant="rectangular" width={200} height={58}></Skeleton>}
          </div>
        </Grid>
        <Grid item md={5}>
         <div className='center'>
         {imageSrc ?
            <div className="image-container">
              <img src={imageSrc} alt='not found' className="fit-image" />
            </div> :
            <Skeleton className="image-container" variant="rectangular" width={300} height={300} />
          }
         </div>
        </Grid>
      </Grid>
    )
  }
  function ShowSecret() {
    return (
      <>
        <Grid container sx={{ justifyContent: 'center' }}>
          <Grid item md={6}>
            <div className='center'>
              <div style={{ color: 'darkblue' }}> {!view ? <p>There is  secret for you... !</p> : <p>Your secret is </p>} </div>
              <div className='secret'>
                {!view &&
                  <Tooltip title="Careful we'll show it only one time">
                    <Button sx={{ color: 'darkblue' }} onClick={() => { setView(true) }} variant="outlined" startIcon={<VisibilityIcon />}>
                      View
                    </Button>
                  </Tooltip>}

              </div>
              <div>
                {view ?
                  <div>
                    <Box component="span" sx={{  paddingX: '50px',paddingY:'30px', borderRadius: '10px', margin: '10px', border: '1px dashed grey' }}>
                      {secret}
                    </Box>
                  </div> : ''}
              </div>
            </div>
          </Grid>
          <Grid item md={4}>
           <div className='center'>
           <div className="image-container">
              <img src={sv} alt='not found' className="fit-image" />
            </div>
           </div>
          </Grid>
        </Grid>
      </>
    )
  }



  return (
    <> <CssBaseline />
      <Container maxWidth="100%">
        <Box
          sx={{
            display: 'flex',
            '& > :not(style)': {
              m: 1,
              width: '100%',
              height: '89vh',
            },
          }}
        >
          <Paper variant="outlined" >
            {secret ?
              <ShowSecret />
              : <Empty></Empty>
            }
          </Paper>
        </Box>
      </Container>
    </>
  )
}

export default Secret


