import React from 'react'
import Grid from '@mui/material/Grid'
import ib from './ib.png'
import home from './Group.svg'
import { Link } from 'react-router-dom'
import './header.css'
const Header = () => {


  return (
    <>
      <Grid container justifyContent='flex-end'>
      
          <div className='headerBar' >
            <img style={{ height: '50px'}} src={ib} alt="Innoboon" />
            <Link to={'/'}>
              <img style={{ height: '30px'}} src={home} alt="Home" />
            </Link>
          </div>

      
      </Grid>
    </>
  )
}

export default Header