import axios from 'axios';
import { backendBaseUrl } from '../urlConfig';
const axiosConfig = axios.create({
  baseURL: backendBaseUrl,
});

axiosConfig.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);
axiosConfig.interceptors.response.use(
  (response) => {
    if (response.status === 200 || response.status === 201) {
      return response;
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosConfig;
