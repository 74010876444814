import React, { useEffect, useRef, useState } from 'react';
// import { useSelector ,useDispatch} from 'react-redux';
import './pass.scss';
import copy from 'clipboard-copy';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
// import { TextareaAutosize } from '@mui/base/TextareaAutosize';
// import { styled } from '@mui/system';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import InsertLinkRoundedIcon from '@mui/icons-material/InsertLinkRounded';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import CryptoJS from 'crypto-js';
import axiosConfig from '../../axios/axios';
import { frontendBaseUrl } from '../../urlConfig'; 
import CircularProgress from '@mui/material/CircularProgress';
const pass = require('password-shuffler-and-generator');



// import { useTheme, useThemeUpdate } from '../../context/Context';
// import { authActions } from '../../Store/Auth';
// import { getData } from '../../Store/userData';

const PassGen = () => {
  const [len, setLen] = useState(4);
  const [pwd, setPwd] = useState('');
  const [uc, setUc] = useState(true);
  const [lc, setLc] = useState(true);
  const [no, setNo] = useState(true);
  const [sc, setSc] = useState(true);
  const [isCopied, setIsCopied] = useState(false);
  const [link, setLink] = useState('');
  const [ttl, setTtl] = useState(1);
  const [secret, setSecret] = useState('');
  const [open, setOpen] = React.useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [isLoading, setisLoading] = useState(false);
  const showErrorSnackbar = (message, type) => {
    setSnackbarSeverity(type ? type : 'error');
    setSnackbarMessage(message);
    setOpen(true);
  };
  const secretKey = process.env.REACT_APP_SECRET;
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  // const dark = useTheme();
  // const data = useSelector((state)=>state.list.data)
  // const load = useSelector((state)=>state.list.loading)
  // const toggleTheme = useThemeUpdate();
  // const isLogged = useSelector((state)=>state.auth.isLogged)
  // const dispatch = useDispatch();
  // const themeStyle ={
  //   backgroundColor: dark?'black':'white',
  //   color:dark?'white':'inherit',
  //   minHeight:'90vh'
  // }
  useEffect(() => { }, [len, uc, lc, no, sc]);
  const prevInputTextRef = useRef('');
  const passField = useRef();
  const generatePassword = () => {
    var shuffled = '';
    if (uc) {
      shuffled += pass.generateShuffledPassFromInputChars('upper', 8);
    }
    if (lc) {
      shuffled += pass.generateShuffledPassFromInputChars('lower', 8);
    }
    if (no) {
      shuffled += pass.generateShuffledPassFromInputChars('number', 8);
    }
    if (sc) {
      shuffled += pass.generateShuffledPassFromInputChars('special', 8);
    }

    setPwd(pass.shuffleAndExtractNCharacters(shuffled, len));
    passField.current.focus();
    setLink('');
    isCopied && setIsCopied(false);
  };
  const data = {
    pwd: CryptoJS.AES.encrypt(secret, secretKey).toString(),
    ttl: parseInt(ttl),
  };
  function getLink() {
    if (!secret) {
      showErrorSnackbar('Message box is empty');
      return;
    }
    if (prevInputTextRef.current.value === secret && link) {
      showErrorSnackbar('same msg ', 'warning');
      return;
    }
    try {
    setisLoading(true)
    showErrorSnackbar('getting link...','info')
      axiosConfig
        .post('pass-gen', data)
        .then((res) => {
          setisLoading(false)
          showErrorSnackbar('Link generated...','success')
          setLink(`${frontendBaseUrl}pass-gen/${res.data.id}`);
        })
        .catch((err) => {
          setisLoading(false)
          showErrorSnackbar(err.message);
        });

    } catch (error) {
      setisLoading(false)

      showErrorSnackbar(error.message);

    }

  }
  const handleCopyClick = () => {
    copy(link ? link : pwd)
      .then(() => {
        setIsCopied(true);
        showErrorSnackbar('Link copied ', 'success')
        setTimeout(() => setIsCopied(false), 2000); // Reset "isCopied" after 2 seconds
      })
      .catch((err) => {
        console.error('Failed to copy text: ', err);
      });
  };
  const marks = [
    {
      value: 4,
      label: '4',
    },
    {
      value: 32,
      label: '32',
    },
  ];

  function valuetext(value) {
    return `${value}°C`;
  }
  const blue = {
    100: '#DAECFF',
    200: '#b6daff',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
    900: '#003A75',
    1000: '#B1B1B1',
  };

  const grey = {
    50: '#f6f8fa',
    100: '#eaeef2',
    200: '#d0d7de',
    300: '#afb8c1',
    400: '#8c959f',
    500: '#6e7781',
    600: '#57606a',
    700: '#424a53',
    800: '#32383f',
    900: '#24292f',
  };
  // const StyledTextarea = styled(TextareaAutosize)(
  //   ({ theme }) => `
  //   width: 520px;
  //   font-family: IBM Plex Sans, sans-serif;
  //   font-size: 0.875rem;
  //   font-weight: 400;
  //   line-height: 1.5;
  //   padding: 12px;
  //   border-radius: 12px 12px 0 12px;
  //   color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  //   background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  //   border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  //   box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]
  //     };
  
  //   &:hover {
  //     border-color: ${blue[400]};
  //   }
  
  //   &:focus {
  //     border-color: ${blue[400]};
  //     box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[500] : blue[200]
  //     };
  //   }
  
  //   // firefox
  //   &:focus-visible {
  //     outline: 0;
  //   }
  // `
  // );

  function handleSecretText(event) {
    setLink('');
    setOpen(false);
    const newText = event.target.value;
    setSecret(newText);
  }

  const oneTime =
    'Create a link and share the text with  one time view for upto 1 day 1 week lifetime ';
  const buttonStyle = {
    width: '70%',
    padding: '10px',
    boderRadius:'15px',
    backgroundColor: 'white',
    '&:focus': {
      boxShadow: '',
      backgroundColor: 'darkblue',
    },
    '&:active': {
      backgroundColor: 'darkblue',
    },
  };


  const CustomSnackbar = ({ open, onClose, message, severity }) => {
    return (
      <Snackbar
        open={open}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={2500}
        onClose={onClose}
      >
        <Alert severity={severity} onClose={onClose}>
          {message}
        </Alert>
      </Snackbar>
    );
  };
  return (
    <Grid sx={{ display: 'flex', justifyContent: 'space-around', gap: '20px ' }} container>
      <Grid item md={5}>
        <Box>
          <div className="header">
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-around',
                gap: '5px',
              }}
            >
              <div className="title">
                <Typography sx={{ color: 'darkblue' }} variant="h4">
                  Password generator
                </Typography>
              </div>

              <div style={{ display: 'flex', margin:'0px 20px 0px 30px ' }}>
                <Grid container spacing={6}>
                  <Grid item xs={9} sm={9}>
                    <input
                      style={{ fontSize: '12px', width: '100%', paddingTop:'17px', paddingBottom:'17px' }}
                      type="text"
                      defaultValue={pwd}
                      readOnly
                    />
                  </Grid>
                  <Grid item xs={3} sm={2}>
                    <button
                      ref={passField}
                      disabled={!pwd}
                      onClick={handleCopyClick}
                      className="copy-button"
                      style={{ width: '100%', height: '100%' }}
                    >
                      <ContentCopyRoundedIcon />
                    </button>
                  </Grid>
                </Grid>
              </div>


              <div className="password">
                <Grid item sm={3}>
                  <Typography
                    sx={{
                      color: 'darkblue',
                      fontWeight: '500',
                      marginRight: '10px',
                    }}
                    variant="p"
                  >
                    Password Length
                  </Typography>
                </Grid>
                <Grid item xs={7} sm={5}>
                  <Slider
                    sx={{
                      color: 'darkblue',
                      alignItems: 'center',
                      marginX: '10px',
                    }}
                    aria-label="Custom marks"
                    defaultValue={20}
                    max={32}
                    min={4}
                    getAriaValueText={valuetext}
                    step={2}
                    onChange={(e) => {
                      setLen(parseInt(e.target.value));
                    }}
                    value={len}
                    valueLabelDisplay="auto"
                    marks={marks}
                  />
                </Grid>
                <Grid item sm={3}>
                  <input
                    style={{width:'60px', height:'40px'}}
                    className="number"
                    type="text"
                    onChange={(e) => {
                      setLen(parseInt(e.target.value));
                    }}
                    value={len}
                  />
                </Grid>
              </div>
              <Grid container>
                <Grid item sm={3}>
                  <span>
                    <Checkbox
                      sx={{
                        '&.Mui-checked': {
                          color: 'darkblue',
                        },
                      }}
                      value=""
                      checked={uc}
                      onChange={(e) => {
                        setUc(e.target.checked);
                      }}
                    />
                  </span>
                  <span className="letter">Uppercase</span>
                </Grid>
                <Grid item sm={3}>
                  <span>
                    <Checkbox
                      sx={{
                        '&.Mui-checked': {
                          color: 'darkblue',
                        },
                      }}
                      value=""
                      checked={lc}
                      onChange={(e) => {
                        setLc(e.target.checked);
                      }}
                    />
                  </span>
                  <span className="letter">Lowercase</span>
                </Grid>
                <Grid item sm={3}>
                  <span>
                    <Checkbox
                      sx={{
                        '&.Mui-checked': {
                          color: 'darkblue',
                        },
                      }}
                      value=""
                      checked={no}
                      onChange={(e) => {
                        setNo(e.target.checked);
                      }}
                    />
                  </span>
                  <span className="letter">Numbers</span>
                </Grid>
                <Grid item sm={3}>
                  <span>
                    <Checkbox
                      sx={{
                        '&.Mui-checked': {
                          color: 'darkblue',
                        },
                      }}
                      value=""
                      checked={sc}
                      onChange={(e) => {
                        setSc(e.target.checked);
                      }}
                    />
                  </span>
                  <span className="letter">Special chars</span>
                </Grid>
              </Grid>
              <div>
                <button className="gen-btn" onClick={generatePassword}>
                  Generate Password
                </button>
              </div>
            </div>
          </div>
        </Box>
      </Grid>
      <Grid item md={6}>
        <Box className="header">
          <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
            <div className="title">
              <Typography
                sx={{ color: 'darkblue' }}
                variant="h4"
                color="initial"
              >
                Drop your one time secret
              </Typography>
            </div>
            <Tooltip sx={{ marginBottom: '20px' }} title={oneTime}>
              <IconButton>
                <HelpOutlineRoundedIcon />
              </IconButton>
            </Tooltip>
          </div>
          <div>
            <textarea
              sx={{
                boderRadius:'15px',
                borderColor: 'darkblue',
                '&:focus': {
                  borderColor: 'darkblue',
                  boxShadow: '0 0 0 2px rgba(0, 0, 255, 0.3)',
                },
              }}
              ref={prevInputTextRef}
              value={secret}
              onChange={handleSecretText}
              minRows={5}
              placeholder="Type something..."
              style={buttonStyle}
            />
            <div
              style={{
                display: 'flex',
                marginRight: '90px',
                padding: '20px',
                justifyContent: 'end',
              }}
            >
              <Box sx={{ maxWidth: 130 }}>
                <FormControl>
                  <InputLabel variant="standard" htmlFor="uncontrolled-native">
                    Link expires after
                  </InputLabel>
                  <NativeSelect
                    sx={{ color: 'darkblue' }}
                    defaultValue={1}
                    onChange={(e) => {
                      setTtl(e.target.value);
                    }}
                    inputProps={{
                      name: 'expiry',
                      id: 'uncontrolled-native',
                    }}
                  >
                    <option value={1}>One day</option>
                    <option value={2}>Two day </option>
                    <option value={3}>One week </option>
                  </NativeSelect>
                </FormControl>
              </Box>
            </div>
            <div style={{ margin: '30px' }}>
              <button
                onClick={getLink}
                style={{ width: '150px' }}
                className="copy-button"
                disabled={isLoading} // Disable the button while loading
              >
                <span>{isLoading ? 'Loading...' : 'Get Link'}</span>{' '}
                <span>
                  {isLoading?<CircularProgress size={24} color="inherit" />:<InsertLinkRoundedIcon sx={{ alignContent: 'center' }} />}
                </span>
              </button>
              <CustomSnackbar
                open={open}
                onClose={handleClose}
                severity={snackbarSeverity}
                message={snackbarMessage}
              />
            </div>

            {link && secret && (
              <>
                <textarea
                  style={{width:'55%', borderRadius:'10px', padding:'5px'}}
                  maxRows={4}
                  aria-label="maximum height"
                  value={link}
                />
                <Button
                  sx={{ marginBottom: '35px', color: 'darkblue' }}
                  startIcon={<FileCopyIcon />}
                  onClick={handleCopyClick}
                ></Button>
              </>
            )}
          </div>
        </Box>
      </Grid>
    </Grid>
  );
};

export default PassGen;
