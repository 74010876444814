import React from 'react'
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import './Home.scss';
import secure from './Group 2.svg';
import  json from './Group 5.svg'
const Home = () => {

    const functionalities = [{ name: "Secret share", desc: 'Create and share the secret and generate password ', link: '/secret' },
    { name: "Json Validator", desc: 'Validate json data ', link: '/jsonLint' },
    { name: "Diff Checker", desc: 'Check differences', link: '/diffChecker' }
]

    function Card({ data }) {
        const navigate = useNavigate();

        const routeHandler = (link = '/') => {
            navigate(link)
        }

        return (<>
            <Button onClick={() => { routeHandler(data.link) }}>
                <div class="card img">

                    <div style={{display:'flex', justifyContent:'center', borderBottom:'4px solid #3A84EE ',borderRadius:'10px', padding:'35px', marginLeft:'20px', marginRight:'20px'}}>
                     <img className='img' src={data.name=='Secret share'?secure:json} alt="Innoboon" />
                    </div>
                   <div>
                   <p className='cardName'>
                    {data.name}
                   </p>
                   <p style={{ padding: '20px', color:'gray' }} class="card-description">{data.desc}</p>
                   </div>
                </div>
            </Button>

        </>)
    }

    return (
        <div style={{ height: '100vh',marginTop:'200px'}}>
            <div className='container'>
                {functionalities.map((x, i) => (<Card key={i} data={x} ></Card>))}
            </div>

        </div>
    )
}

export default Home