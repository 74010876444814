import React from 'react';
import './file.scss';
import { FaUpload, FaTrash } from 'react-icons/fa';

const pageData = {
  noFile: 'No file selected',
};

const FileUpload = ({ fileName, handleFileChange, type ,deleteFile}) => {

  const handleUploadClick = () => {
    document.getElementById(`fileInput${type}`).click();
  };

  const FileContent=(file)=>{
    return (
      <div style={{display:'flex', justifyContent:'space-around', width:'70%'}}>
        <p className='fileInfo'>{fileName ? fileName : pageData.noFile}</p>
        {fileName&&<button onClick={deleteFile} className='deleteBtn'><FaTrash /> </button>}
      </div>
    )

  }

  return (
    <div className='uploadBox'>
      <input
        id={`fileInput${type}`}
        type='file'
        style={{ display: 'none' }}
        onChange={(e) => {
          handleFileChange(e); // Pass fileType directly to the handleFileChange function
        }}
      />
      <FaUpload
        className='uploadIcon'
        style={{ position: 'relative', zIndex: 1, cursor: 'pointer' }}
        onClick={handleUploadClick}
      />
     <FileContent/>
    </div>
  );
};

export default FileUpload;
