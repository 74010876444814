import React, { useState } from 'react';
import ReactDiffViewer from 'react-diff-viewer';
import './diff.scss'
import { FaUpload } from 'react-icons/fa';
import FileUpload from '../../commonComponents/fileUpload/FileUpload';

// Assuming you are using react-icons for icons
const pageData = {
  title: 'Difference Checker',
  fileType: { old: 'old', new: 'new' },
  'compareNew': 'Compare New',
  'compare': 'Compare',
  'err':'format unsupported !  ',
  'supportedFormats':['txt', 'json', 'yaml', 'yml','tf' ],
}
const DiffChecker = () => {
  const [oldFileContent, setOldFileContent] = useState('');
  const [newFileContent, setNewFileContent] = useState('');
  const [oldFile, setOldFile] = useState();
  const [newFile, setNewFile] = useState();
  const [show, setShow] = useState(false);
  const [oldFileErr, setOldFileErr] = useState('');
  const [newFileErr, setNewFileErr] = useState('');
  

  function showHandler(show, setShow) {
    setShow(!show)
  }

  const handleOldFileChange = (e) => {

    const file = e.target.files[0];
    let fileContent;
    if (file) {
      const fileNamePart = file.name.split('.')
      const fileExtension = fileNamePart?fileNamePart[fileNamePart.length-1].toLowerCase():""
      if(pageData.supportedFormats.includes(fileExtension)){
        const reader = new FileReader();
        reader.onload = (e) => {
          fileContent = e.target.result;
            setOldFileContent(fileContent);
            setOldFile(file.name);
        };
        reader.readAsText(file);
      }
      else{
        setOldFileErr(`${fileExtension.toUpperCase()}   ${pageData.err}`)
        setTimeout(()=>{
          setOldFileErr('')
        },2000)
      }

    }
  };

  const handleNewFileChange = (e) => {

    

    const file = e.target.files[0];
    let fileContent;
    if (file) {
      const fileNamePart = file.name.split('.')
      const fileExtension = fileNamePart?fileNamePart[fileNamePart.length-1].toLowerCase():""
      if(fileExtension==='json'|| fileExtension ==='txt')   {
      const reader = new FileReader();
      reader.onload = (e) => {
        fileContent = e.target.result;
          setNewFileContent(fileContent);
          setNewFile(file.name);
      };
      reader.readAsText(file);
     }
     else{
      setNewFileErr(pageData.err)
      setTimeout(()=>{
        setNewFileErr('')
      },2000)
     }
    }
  };

  function deleteOldFile(){
    setOldFile('');
    setOldFileContent('')
  }
  function deleteNewFile(){
    setNewFile('');
    setNewFileContent('')
  }
  
  

  return (
    <div>

      <div className='pageHeader' >
        <div >
          <p className='title'>{pageData.title}</p>
        </div>
        {
          show &&
          <div>
            <button onClick={() => { showHandler(show, setShow) }} style={{ width: '200px', }} className='gen-btn'>
              {pageData.compareNew}
            </button>
          </div>
        }
      </div>

      {!show ?
        <div className='inputContainer'>
          <div >
            <textarea className='fileInput'
              spellCheck={false}
              value={oldFileContent}
              placeholder='Old file content'
              onChange={(e) => setOldFileContent(e.target.value)}
            />
            <div style={{ position: 'relative' }}>
              <div className='fileSection'>

                <FileUpload handleFileChange={handleOldFileChange} fileName={oldFile} type={pageData.fileType.old} deleteFile={deleteOldFile} />
                {oldFileErr ? <div
                  className='errorSection'>
                  <p >{oldFileErr}</p>
                </div> : ''}
              </div>
            </div>
          </div>

          <div>
            <button disabled={!(oldFileContent || newFileContent)} onClick={() => { showHandler(show, setShow) }} style={{ width: '100px' }} className='gen-btn'>
              {pageData.compare}
            </button>
          </div>

          <div>
            <textarea className='fileInput'
              value={newFileContent}
              spellCheck={false}
              placeholder='New file content'
              onChange={(e) => setNewFileContent(e.target.value)}
            />

            <div style={{ position: 'relative' }}>
              <div className='fileSection'>
                <FileUpload handleFileChange={handleNewFileChange} fileName={newFile} type={pageData.fileType.new} deleteFile={deleteNewFile} />
                {newFileErr ? <div
                  className='errorSection'>
                  <p >{newFileErr}</p>
                </div> : ''}
              
              </div>
            </div>
          </div>
        </div>
        : <div className='diffContainer' style={{ border: !(newFileContent || oldFileContent) ? "none" : '1px solid #e3dddc' }}>
          <ReactDiffViewer
            oldValue={oldFileContent}
            newValue={newFileContent}
            splitView={true}
          />
        </div>}
    </div>
  );
};

export default DiffChecker;
